import { graphql } from "gatsby"
import React, { useContext } from "react"
import { MetaTagsContext } from "../layouts"

const TermsOfUse = ({ data }) => {
  const { setMetaTags } = useContext(MetaTagsContext)

  setMetaTags(data.strapiTermsOfUse.seo)

  return (
    <div className="bg-white">
      <div className="container py-16">
        {data.strapiTermsOfUse && (
          <>
            <h1
              className="font-light text-36px leading-tight tracking-tight pb-6
                md:pb-8 md:leading-snug
                lg:text-5xl lg:leading-normal
                xl:pb-16 xl:text-80px"
            >
              {data.strapiTermsOfUse.title}
            </h1>
            <div
              className="whitespace-pre-wrap
                lg:text-xl
            "
            >
              {data.strapiTermsOfUse.terms}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export const query = graphql`
  {
    strapiTermsOfUse {
      title
      terms
      seo {
        title
        description
      }
    }
  }
`

export default TermsOfUse
